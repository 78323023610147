<template>
  <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
       v-bind:class="classObject" v-on:click="showInfo"
       viewBox="0 0 473.733 473.733" style="enable-background:new 0 0 473.733 473.733;" xml:space="preserve">
<g>
  <path fill="currentColor" d="M231.2,336.033c-9.35,0-17,7.65-17,17v11.333c0,9.35,7.65,17,17,17s17-7.65,17-17v-11.333
			C248.2,343.683,240.55,336.033,231.2,336.033z"/>
  <path fill="currentColor" d="M236.867,473.733c130.617,0,236.867-106.25,236.867-236.867S367.483,0,236.867,0S0,106.25,0,236.867
			S106.25,473.733,236.867,473.733z M236.867,34c111.917,0,202.867,90.95,202.867,202.867s-90.95,202.867-202.867,202.867
			S34,348.783,34,236.867S124.95,34,236.867,34z"/>
  <path fill="currentColor" d="M163.2,194.367C163.483,194.367,163.483,194.367,163.2,194.367c9.35,0,17-7.083,17-16.433c0,0,0.283-13.6,7.083-26.917
			c8.5-17,23.517-25.5,45.617-25.5c20.683,0,35.983,5.667,44.483,16.717c7.083,9.067,9.067,21.533,5.667,35.133
			c-4.25,16.717-18.7,31.167-32.583,45.333c-17,17.567-34.85,35.417-34.85,59.5c0,9.35,7.65,17,17,17s17-7.65,17-17
			c0-10.2,12.183-22.667,25.217-35.7c16.15-16.433,34.567-35.133,41.083-60.633c6.233-23.517,1.983-47.033-11.617-64.317
			c-10.483-13.6-31.45-30.033-71.117-30.033c-44.483,0-65.733,23.8-75.933,44.2c-10.2,20.4-10.767,39.95-10.767,42.217
			C146.483,187,153.85,194.367,163.2,194.367z"/>
</g>
</svg>
</template>
<script>


export default {
  name: "QuestionIcon",
  props: {
    row: {
      required: true
    },
    id: {
      required: true
    },
    hasDiff: {
      required: true
    },
    strokeColor: {
      type: String,
      required: false
    }
  },
  computed: {
    classObject: function () {
      let classes = ['cursor'];
      if (this.hasDiff) {
        classes.push(this.class);
        classes.push('stroke-' + this.strokeColor)
      }

      return classes;
    }
  },
  methods: {
    showInfo: function () {
      this.$emit("showInfo", this.row, this.id);
    }
  }
}
</script>

<style scoped>

svg {
  width: 15px;
  height: 15px;
  cursor: pointer;
}

.orange {
  color: orange;
}

.yellow {
  color: yellow;
}

.red {
  color: lightcoral;
}

.blue {
  color: blue;
}

.green {
  color: green;
}

.purple {
  color: purple;
}

.brown {
  color: brown;
}
</style>
