<template>
  <svg
      id="Flat"
      v-on:click="showRetestResults"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 256 256"
      v-tooltip="'Click to see second attempt results'"
      style="enable-background:new 0 0 473.733 473.733;">
    <path fill="#FF0000" d="M128,24A104,104,0,1,0,232,128,104.11791,104.11791,0,0,0,128,24Zm24,143.99414a8,8,0,0,1,0,16H104.31738c-.10644.00391-.21289.00684-.31836.00684a8.00343,8.00343,0,0,1-6.30175-12.93164L141.37012,112.794a16.00416,16.00416,0,1,0-28.11621-15.01954A8,8,0,1,1,98.51758,91.542a32.00411,32.00411,0,1,1,56.01269,30.35547c-.07324.1084-.14843.21484-.22754.31934l-34.30566,45.77734Z"/>
  </svg>
</template>
<script>


export default {
  name: "RetestIcon",
  props: {
    engine: {
      required: true
    },
  },

  methods: {
    showRetestResults: function () {
      this.$emit("showRetestResults", this.engine);
    }
  }
}
</script>

<style scoped>

svg {
  width: 19px;
  height: 19px;
  color: red;
  cursor: pointer;
}

</style>
